<template>
    <BaseLayout>
            <script v-html="jsonld" type="application/ld+json"></script>
        	<section class="ol-news">
                <div class="ol-wrapper ol-wrapper--large">
                    
                    <div class="ol-breadcrumb">
                        <a href="/" class="ol-breadcrumb__link">Home</a> ›
                        <a href="/artikel" class="ol-breadcrumb__link">Artikel</a>  ›
                        
                        <a v-if="article.r_uni_news_categories && article.r_uni_news_categories[0] && article.r_uni_news_categories[0].name_id" 
                        class="ol-breadcrumb__link" 
                        :href="searchCategory(article.r_uni_news_categories[0].name_id)" > 
                        {{ article.r_uni_news_categories[0].name_id }} ›
                        </a>
                        <a v-if="!loading" href="#" class="ol-breadcrumb__link">{{article.title}}</a>
                    </div>
                    <h1 class="ol-home-news__list-item-desc-detail">
                        {{article.title}}
                    </h1>
                    <!-- ol-home-news__list-item-images -->
                    <img :srcset="article.image_url" :alt="article.title" :title="article.title" width="100%">
                    <!-- PRODUK TERKAIT -->
                    <div v-if="products.length > 0" style="margin-bottom: 20px;">
                        <div class="ol-product-content ol-product-related add-fix">
                            <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingRelated"/>
                            <ProductNews :products="products" v-if="!loadingRelated" />
                        </div>
                    </div>
                    <!-- /PRODUK TERKAIT -->

                    <div class="ol-news__wrapper add-fix">
                        <!-- ol-news__list -->
                        <div  class="ol-news__list">
                            <ArtikelLoader v-if="loading" :qty="1" />
                            <!-- ol-news__detail -->
                            <div v-if="!loading" class="ol-news__detail">
                                
                                <!-- <a href="/moreversary">
                                    <img :src="require('@/assets/images/retail/Banner-970x250.jpg')" class="retail-mobile"  width="100%" style="border-radius: 8px;margin-top:8px">
                                </a> -->
                                <!-- ol-home-news__list-item-desc -->
                                <div class="ol-home-news__list-item-desc">
                                    <article v-html="article.content" class="ol-news__detail-text">
                                        {{article.content}}
                                    </article>
                                </div>
                                <!-- /ol-home-news__list-item-desc -->

                            </div>
                            <!-- /ol-news__detail -->

                            <!-- ol-news__detail-bottom -->
                            <div v-if="!loading" class="ol-news__detail-bottom">
                                <div class="add-fix">
                                    <div class="ol-news__detail-bottom-tag">
                                        TAGS : 
                                        <span v-for="item in article.r_uni_news_tags" :key="item.id">
                                            <router-link :to="'/artikel?tags='+item.name">
                                            {{item.name}}
                                            </router-link>,
                                        </span>
                                    </div>
                                    <div class="ol-news__detail-bottom-share">
                                    SHARE :
                                    <!-- <a href="#" onclick="fb_share('The Lost World Castle Tetap Buka Meski Merapi Semburkan Awan Panas','{{url()->full()}}')" title="Facebook"><i class="ol-fa fab fa-facebook-f "></i></a> -->
                                    <a :href="'https://api.whatsapp.com/send/?phone&text='+article.title+'. https://morefurniture.id/artikel/'+article.slug" target="_blank"  title="Whatsapp"><i class="ol-fa fab fab fa-whatsapp"></i></a>
                                    </div>
                                </div>

                                <div class="ol-news__detail-bottom-paging add-fix">
                                    <div v-if="article.prev_page" class="ol-news__detail-bottom-paging-item ol-news__detail-bottom-paging-item--left">
                                        <div class="add-fix">
                                            <figure>
                                                <img v-on:click="toDetail(article.prev_page.slug)" :srcset="article.prev_page.image_url" :alt="article.prev_page.title" :title="article.prev_page.title">
                                            </figure>
                                            <a v-on:click="toDetail(article.prev_page.slug)"><i>&laquo;</i> Prev</a>
                                            <h3>
                                                <a v-on:click="toDetail(article.prev_page.slug)">{{article.prev_page.title}}</a>
                                            </h3>
                                        </div>
                                    </div>

                                    <div v-if="article.next_page" class="ol-news__detail-bottom-paging-item ol-news__detail-bottom-paging-item--right">
                                        <div class="add-fix">
                                            <figure>
                                                 <img v-on:click="toDetail(article.next_page.slug)" :srcset="article.next_page.image_url" :alt="article.next_page.title" :title="article.next_page.title">
                                            </figure>
                                            <a v-on:click="toDetail(article.next_page.slug)">Next <i>&raquo;</i></a>
                                            <h3>
                                                <a v-on:click="toDetail(article.next_page.slug)">{{article.next_page.title}}</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /ol-news__detail-bottom -->
                        </div>
                        <!-- /ol-news__list -->

                        <!-- ol-news__sidebar -->
                         <Tag @triggerClick="goTags"/>
                        <!-- /ol-news__sidebar -->
                    </div>
                </div>
            </section>

            
    </BaseLayout>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import BaseLayout from '@/components/landing/BaseLayout.vue';
import ArtikelLoader from '@/components/loading/ArtikelLoader.vue';
import ProductLoader from '@/components/loading/product/Content.vue';
import ProductNews from '@/components/product/ProductNews.vue';
import Tag from '@/components/artikel/Tag.vue';
export default {
    name: "ArtikelDetail",
    metaInfo() {
        return{
            meta: [
                { name: 'title', content:this.article.title_meta},
                { name: 'description', content: this.article.description_meta ? this.article.description_meta : ''},
                { name: 'keywords', content: this.article.keyword ? this.article.keyword : ''},
                { name: 'robots', content: 'index,follow'},
                { property: 'og:title', content: this.article.title_meta},
                { property: 'og:url', content: window.location.origin+this.$route.fullPath}, 
                { property: 'og:description', content: this.article.description_meta ? this.article.description_meta : ''},
                { property: 'og:site_name', content: 'More Furniture'},
                { property: 'og:type', content: 'website'}    
            ],
            link: [
                {rel: 'canonical', href: this.article.canonical ? this.article.canonical : window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components:{
        BaseLayout,Tag,ArtikelLoader,ProductLoader,ProductNews
    },
     data() {
        return {
            loading:true,
            loadingRelated: true,
            DATA:null,
            jsonld:null,
        }
     },
    computed:{
        ...mapGetters("artikel", ["article", "products"]),
    },
    methods:{
        ...mapActions("artikel", ["fetchDetail", "fetchProduct"]),
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        searchCategory(input){
            // Convert to lowercase
            let lowerCased = input.toLowerCase();
    
            // Replace spaces with hyphens
            let hyphenated = lowerCased.replace(/\s+/g, '-');

            return "https://morefurniture.id/artikel/category/"+hyphenated;
        },
        async getData(){
            let result = await this.fetchDetail(this.$route.params.slug);
            await this.fetchProduct(this.article.id);
            this.loading = false;
            this.loadingRelated = false;
            this.DATA = result.data;
            document.title = this.article.title_meta;
            // console.log(result)
            if(!result.success){
                this.$router.push("/404");
                return false;
            }
        },
        toDetail(slug){
            location.href = '/artikel/'+slug;
            // this.$router.replace({ path: '/artikel/'+slug});
            // this.loading =true;
            // this.getData();
        },
        goTags(tag){
            this.$router.push({ path: "/artikel", query: {tags: tag.name} });
            this.tags = tag.name;
            // this.getData(this.page);
        },
    },
    async mounted(){
        // this.jqScrolling2top();
        this.getData();
        this.jsonld = {
            "@context": "https://schema.org/",
            "@type": "Article",
            "headline": this.article.seo_1,
            "image": this.article.image_url,
            "author": this.article.seo_2,
            "award": this.article.seo_3,
            "editor": this.article.seo_4,
            "genre": this.article.seo_5,
            "keywords": this.article.keyword,
            "wordcount": this.article.seo_6,
            "url": window.location.origin+this.$route.fullPath,
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://morefurniture.id/artikel"
            },
            "datePublished": this.article.date,
            "dateCreated": this.article.date,
            "dateModified": this.article.date,
            "description": this.article.description_meta
        };

        $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
    }
}
</script>
<style scoped lang="css">
@import '../../assets/css/blog.css';

.align-center{
    text-align:center
}
</style>