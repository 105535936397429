<template>
<aside class="ol-news__sidebar">
    <div class="ol-news__sidebar-list">
        
        <!-- More Sidebar 300x250 -->
        <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-4731670059474738"
            data-ad-slot="6878219215"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        <div class="ol-news__sidebar-list-item">
            <h4 class="ol-news__sidebar-list-item-title">Artikel Pilihan</h4>
            <ul class="ol-news__sidebar-list-item-content ol-news__sidebar-list-item-content--new">
                <li v-for="item in articleSelected" :key="item.slug" class="add-fix" >
                    <figure>
                        <img :src="item.image_url" width="100%" :alt="item.title" :title="item.title">
                    </figure>
                    <div>
                        <a v-bind:href="'/artikel/'+item.slug">{{ item.title }}</a>
                        <span>{{ item.date_indo }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="ol-news__sidebar-list-item">
            <h4 class="ol-news__sidebar-list-item-title">Top Tags</h4>
            <div v-if="loading" class="ol-news__sidebar-list-item-content ol-news__sidebar-list-item-content--tag">
                <a style="width:150px;height:25px;"><SkeletonLoader /></a>
                <a style="width:60px;height:25px;"><SkeletonLoader /></a>
                <a style="width:70px;height:25px;"><SkeletonLoader /></a>
                <a style="width:120px;height:25px;"><SkeletonLoader /></a>
            </div>
            <div v-if="!loading" class="ol-news__sidebar-list-item-content ol-news__sidebar-list-item-content--tag">
                <a v-for="item in tagsTop" :key="item.id" @click="$emit('triggerClick',item)" >{{item.name}}</a>
            </div>
        </div>
    </div>
</aside>
</template>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4731670059474738" crossorigin="anonymous"></script>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>
<script>
import { mapGetters, mapActions } from "vuex";
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name: "Tag",
    components:{
        SkeletonLoader
    },
    data() {
        return {
            loading:true
        }
    },
    computed:{
        ...mapGetters("artikel", ["tagsTop","articleSelected"]),
    },
    methods:{
        ...mapActions("artikel", ["getTagsTop","getArticleSelected"]),  
    },

    async mounted() {
        await this.getTagsTop();
        await this.getArticleSelected();
        this.loading = false;
    }
}
</script>
<style scoped lang="css">
@import '../../assets/css/blog.css';
</style>