<template>
    <div class="ol-product-content__center-list carousel-products-container">

        <div v-for="(product,i) in products" :key="'item-'+i+'-'+product.id+'-'+product.slug">
            <router-link :to="product.bundling == 1 ? '/bundle/'+product.slug : '/product/'+product.slug" :title="product.name">
                <div class="ol-home-product__content-item-list--item" style="width:100%">
                    <div class="more-product-item">
                        <div class="card-product-carausel">
                            <!-- <div v-if="product.stock == 0" >
                                <div v-if="product.stock == 0" class="label-stock-empty" >Stok Habis</div>
                                <div v-if="product.stock == 0" class="image-stock-empty" ></div>
                            </div> -->
                            <div 
                                :style="{
                                backgroundImage: product.r_uni_product_suasanas && product.r_uni_product_suasanas.length > 0 ? 
                                'url(' + product.r_uni_product_suasanas[0].image_url + ')' : 
                                'url(' + (product.r_uni_product_images && product.r_uni_product_images.length > 0 ? product.r_uni_product_images[0].image_url : '') + ')',
                                display: 'block',
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }">
                                <div v-if="product.seo_4 == 'GRATIS'" class="promotion-1">
                                    <span class="one-lines"><b>{{ product.seo_4 }}</b>&nbsp;{{ product.seo_5 }}</span>
                                </div>
                                <div v-if="product.seo_4 == 'FLASH SALE'" class="promotion-1">
                                    <span class="one-lines"><b>{{ product.seo_4 }}</b>&nbsp;{{ product.seo_5 }}</span>
                                </div>
                                <div v-if="product.seo_4 == 'KOMBO HEMAT'" class="promotion-1">
                                    <span class="one-lines"><b>{{ product.seo_4 }}</b>&nbsp;{{ product.seo_5 }}</span>
                                </div>
                                <div v-if="product.seo_4 == 'SALE'" class="promotion-1">
                                    <span class="one-lines">{{ product.seo_5 }}</span>
                                </div>
                                <div v-if="product.seo_4 == 'HOT PRODUCT'" class="promotion-2">
                                    <span class="one-lines">PRODUK TERLARIS</span>
                                </div>
                                <div v-if="product.seo_4 == 'NEW PRODUCT'" class="promotion-2">
                                    <span class="one-lines">PRODUK TERBARU</span>
                                </div>
                            </div>
                        </div>
                        <div class="content-card-product">
                            <h3 class="ol-home-product__content-item-list--item-title">
                                {{product.name}}
                            </h3>
                            <div class="flex-card-product">
                                <div class="price-discount-card-product">{{product.price_normal_min}}</div>
                                <div class="box-discount-card-product">
                                    <div class="discount-card-product">{{product.discount_max}}</div>
                                </div>
                                <div v-if="product.status_voucher != 'Rp0'" class="voucher-discount-card-product">
                                    <img  :src="require('@/assets/images/benefit/voucher.png')"  height="100%">
                                </div>
                            </div>
                            <div class="price-card-product">
                                {{product.price_discount_min}}
                            </div>
                            <div class="review-card-product review-catalog-product" v-if="product.total_sales != 0">
                                <span ><i class="fas fa-star" style="color:#FFCC00;"></i></span>
                                <span> {{product.rating}}</span>
                                <span>|</span>
                                <span>{{product.total_sales}}</span>
                                <span>Terjual</span>
                            </div>
                            <div class="review-card-product" v-else>
                                &nbsp;
                            </div>
                            <img  :src="require('@/assets/images/info-gratis-jawa-bali.png')" class="free-card-product">
                        </div>
                    </div>
                    
                    
                </div>
            </router-link>
        </div>
        
    </div>
</template>

<script>

export default {
    name:"ProductNews",
    props:["products"],
    mounted() {
        this.initSlick();
    },
    methods: {
        initSlick() {
            $(".carousel-products-container").slick({
                slidesToScroll: 1,
                slidesToShow: 5,
                dots: !0,
                autoplay: !0,
                autoplaySpeed: 5e3,
                arrows: !0,
                pauseOnFocus: !1,
                infinite: !0,
                speed: 500,
                fade: !1,
                responsive: [{
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                },
                }],
            });
        }
    }
}
</script>

<style>

@import "../../assets/js/slick/slick.css";
.label-free-product {
  /* height: 35px; */
  border-radius: 0px 0px 8px 8px;
  padding: 8px;
  background: #C13B37;
  margin: -8px 2px 4px 2px;
  color: white;
  font-size: 10px;
  text-align: left;
}

.label-free {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-height: 24px;
  line-height: 12px;
}

</style>