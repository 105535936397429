<template>
<div>
<div v-for="i in qty" :key="i" class="ol-home-news__list-item">

    <!-- ol-home-news__list-item-images -->
    <div class="ol-home-news__list-item-images">
        <figure class="ol-home-news__list-item-images-figure">
            <a href="" style="height:200px;">
                <SkeletonLoader />
            </a>
        </figure>
        <!-- <span class="ol-home-news__list-item-images-date">{{ date('j', strtotime($berita['tanggal'])) }} <i>{{ jdmonthname(date('m', strtotime($berita['tanggal'])),0) }}</i></span> -->
    </div>
    <!-- /ol-home-news__list-item-images -->

    <!-- ol-home-news__list-item-desc -->
    <div class="ol-home-news__list-item-desc">
        <p class="ol-home-news__list-item-desc-text">
            <SkeletonLoader style="with:100%;height:80px;" />
        </p>
        <div  style="width:123px;height:41px">
                <SkeletonLoader />
        </div>
    </div>
    <!-- /ol-home-news__list-item-desc -->

</div>
</div>
</template>
<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"ArtikelLoader",
    props:['qty'],
    components:{
        SkeletonLoader
    },
}
</script>